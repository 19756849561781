// extracted by mini-css-extract-plugin
export var blPosition = "hero-component-module--bl-position--tDxo+";
export var brPosition = "hero-component-module--br-position--U0ywz";
export var centerLeft = "hero-component-module--center-left--yd7c0";
export var centerLeftRecipe = "hero-component-module--center-left-recipe--JcC9n";
export var centerPosition = "hero-component-module--center-position--wiI3F";
export var desktopGatsbyImage = "hero-component-module--desktop-gatsby-image--pmG2d";
export var desktopImage = "hero-component-module--desktop-image--2md65";
export var fullSize = "hero-component-module--full-size--eLpIk";
export var heroContainer = "hero-component-module--hero-container--9MQ5G";
export var heroImage = "hero-component-module--hero-image--xbjf1";
export var marginTop = "hero-component-module--margin-top--S8MgO";
export var mobileGatsbyImage = "hero-component-module--mobile-gatsby-image--1eJHf";
export var mobileImage = "hero-component-module--mobile-image--IFoJF";
export var mobileTitle = "hero-component-module--mobile-title--4guVs";
export var shadow = "hero-component-module--shadow--VY2HF";
export var title = "hero-component-module--title--RKaAr";
import React, { useRef } from 'react'
import * as styles from './section-slider.module.scss'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";

import ArticleCard from '../../Shared/ArticleCard/ArticleCard';

import ArrowLeft from '../../../static/arrow-left.svg';
import ArrowRight from '../../../static/arrow-right.svg';

const SectionSlider = ({ data }) => {
    const {
        sliders
    } = data

    const goNext = (ref) => {
        ref?.current?.swiper?.slideNext()
    }

    const goPrev = (ref) => {
        ref?.current?.swiper?.slidePrev()
    }

    const swiperRef = useRef(null)

    return (
        <div className={styles.container}>
            <div className={styles.prevIcon}>
                <ArrowLeft onClick={() => goPrev(swiperRef)} />
            </div>
            <Swiper
                className={styles.swiperContainer}
                breakpoints={{
                    360: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    1400: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    }
                }}
                ref={swiperRef}
            >
                {
                    sliders.map(({ slug, image, target, title }, index) => (
                        <SwiperSlide key={index}>
                            <ArticleCard
                                title={title}
                                thumbnailImage={image}
                                slug={target?.slug || slug}
                            />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            <div className={styles.nextIcon}>
                <ArrowRight onClick={() => goNext(swiperRef)} />
            </div>
        </div>
    )
}

export default SectionSlider
import React, { useEffect } from 'react'
import * as styles from './section-hero-slider.module.scss';

import HeroComponent from '../HeroComponent/HeroComponent';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";

const SectionHeroSlider = ({
    data,
    index
}) => {
    const {
        heroSliders,
        navigationColor,
        bulletColor,
        activeBulletColor,
        arrowHeight
    } = data
    useEffect(() => {
        document.documentElement.style.setProperty(`--swiper-navigation-color`, navigationColor)
        document.documentElement.style.setProperty(`--swiper-pagination-bullet-inactive-color`, bulletColor)
        document.documentElement.style.setProperty(`--swiper-pagination-color`, activeBulletColor)
        document.documentElement.style.setProperty(`--swiper-navigation-size`, arrowHeight)
        return () => {
            document.documentElement.style.removeProperty(`--swiper-navigation-color`)
            document.documentElement.style.removeProperty(`--swiper-pagination-bullet-inactive-color`)
            document.documentElement.style.removeProperty(`--swiper-pagination-color`)
            document.documentElement.style.removeProperty(`--swiper-navigation-size`)
        }
    }, [])
    return (
        <div className='container container-fs hero-slider hero-image'>
            <Swiper
                loop={true}
                centeredSlides={true}
                autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Navigation, Pagination]}
            >
                {
                    heroSliders.map((section, key) => (
                        <SwiperSlide key={key}>
                            <HeroComponent data={section} index={index} />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div >
    )
}

export default SectionHeroSlider
import React from 'react'
import * as styles from './section-bread-crumbs.module.scss';

import Arrow from '../../../static/breadcrumbs-arrow.svg'

import { Link } from 'gatsby';
import { useLocalPath } from '../../../hooks';

const SectionBreadCrumbs = ({
    data,
    title = null
}) => {
    const {
        pageTitle,
        links
    } = data
    return (
        <section id="breadcrumbs" className={`container container-xl breadcrumbs ${styles.breadcrumbsContainer}`}>
            {
                links?.map(({ title, target }, index) => {
                    return target ? (
                        <span key={index} className={`${styles.div}`}>
                            <Link
                                // className={`${styles.link} ${styles.linkArrow}`}
                                className={`${styles.link}`}
                                to={useLocalPath(target.slug)}
                            >{title}</Link>
                            <span className={styles.arrow}>
                                <Arrow />
                            </span>
                        </span>
                    ) : (
                        <span key={index} className={`${styles.div}`}>
                            <p className={`${styles.link}`}>{title}</p>
                            <span className={styles.arrow}>
                                <Arrow />
                            </span>
                        </span>
                    )
                })
            }
            <span className={`${styles.link} ${styles.div}`}>
                {(title || pageTitle)?.split('/n')?.join('')}
            </span>
        </section>
    )
}

export default SectionBreadCrumbs
import React from 'react'
import * as styles from './article-card.module.scss';

import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useLocale, useLocalPath } from '../../../hooks';
import { mapSiteName } from '../../../utils/mapping';

const ArticleCard = ({
    slug,
    thumbnailImage,
    title
}) => {
    const locale = useLocale()
    return (
        <div className={styles.cardContainer}>
            <div className={styles.imageWrapper}>
                <Link to={useLocalPath(slug)} className={styles.card}>
                    <GatsbyImage
                        image={thumbnailImage.gatsbyImageData}
                        alt={`${title?.split('/n').join('') || thumbnailImage.title.split('/n').join('')} | ${mapSiteName[locale]}`}
                        className={styles.image}
                    />
                </Link>
            </div>
            <p className={styles.swiperTitle}>
                {
                    title?.split('/n').map((phrase, index) => (
                        <span key={index}>{phrase}</span>
                    ))
                }
            </p>
        </div>
    )
}

export default ArticleCard
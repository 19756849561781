import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo';
import HeroComponent from '../components/sections/HeroComponent/HeroComponent';
import SectionBreadCrumbs from '../components/sections/SectionBreadCrumbs/SectionBreadCrumbs';
import SectionHeroSlider from '../components/sections/SectionHeroSlider/SectionHeroSlider';
import SectionSlider from '../components/sections/SectionSlider/SectionSlider';


const Home = ({
    pageContext,
    location,
    data: {
        contentfulPageHome: {
            slug,
            seoTitle,
            seoDescription,
            pageTitle,
            sections
        }
    }
}) => {
    return (
        <div>
            <h1 style={{ display: 'none' }}>{seoTitle}</h1>
            <Seo
                title={seoTitle}
                description={seoDescription?.seoDescription}
                slug={slug}
                home={true}
            />
            <div className='content'>
                {
                    sections.map((section, index) => {
                        switch (section.__typename) {
                            case "ContentfulComponentHero":
                                return (
                                    <HeroComponent data={section} index={index} key={index} />
                                )
                            case "ContentfulSectionBreadCrumbs":
                                return (
                                    <SectionBreadCrumbs data={section} title={pageTitle} key={index} />
                                )
                            case "ContentfulSectionHeroSlider":
                                return (
                                    <SectionHeroSlider data={section} key={index} index={index} />
                                )
                            case "ContentfulSectionSlider":
                                return (
                                    <SectionSlider data={section} key={index} />
                                )
                        }
                    })
                }
            </div>
        </div>
    )
}

export const HomeQuery = graphql`
    query HomeQuery( $id: String! ){
        contentfulPageHome(id: {eq: $id}){
            slug
            seoTitle
            seoDescription {
                seoDescription
            }
            pageTitle
            sections {
                __typename
                ...componentHero
                ...sectionsBreadcrumbs
                ...sectionHeroSlider
                ...sectionSlider
            }
        }        
    }
`

export default Home
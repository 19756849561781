import React from 'react'
import * as styles from './hero-component.module.scss';

import { GatsbyImage } from 'gatsby-plugin-image';
import { mapSiteName } from '../../../utils/mapping';
import { useLocale } from '../../../hooks';

const HeroComponent = ({
    data,
    index,
    recipe = false,
    fullSize = false,
    first_child=true
}) => {
    const {
        title,
        imageObjectFit,
        heroImage,
        heroMobileImage,
        titlePosition,
        titleColor,
        titleMobile,
        containerSize
    } = data

    const locale = useLocale()

    const titlePositionDiv = () => {
        if (recipe) return <div className={styles.centerLeftRecipe}>
            <h1 className={styles.title} style={{ color: titleColor }}>
                {
                    title.split('/n').map((phrase, index) => (
                        <span style={{ display: 'block' }} key={index}>{phrase}</span>
                    ))
                }
            </h1>
        </div>
        switch (titlePosition) {
            case "center":
                return <div className={styles.centerPosition}>
                    <h1 className={styles.title} style={{ color: titleColor }}>
                        {
                            title.split('/n').map((phrase, index) => (
                                <span style={{ display: 'block' }} key={index}>{phrase}</span>
                            ))
                        }
                    </h1>
                </div>
            case "bottom-left":
                return <div className={styles.blPosition}>
                    <h1 className={styles.title} style={{ color: titleColor }}>
                        {
                            title.split('/n').map((phrase, index) => (
                                <span style={{ display: 'block' }} key={index}>{phrase}</span>
                            ))
                        }
                    </h1>
                </div>
            case "bottom-right":
                return <div className={styles.brPosition}>
                    <h1 className={styles.title} style={{ color: titleColor }}>
                        {
                            title.split('/n').map((phrase, index) => (
                                <span style={{ display: 'block' }} key={index}>{phrase}</span>
                            ))
                        }
                    </h1>
                </div>
            case "center-left":
                return <div className={styles.centerLeft}>
                    <h1 className={styles.title} style={{ color: titleColor }}>
                        {
                            title.split('/n').map((phrase, index) => (
                                <span style={{ display: 'block' }} key={index}>{phrase}</span>
                            ))
                        }
                    </h1>
                </div>
            case "none":
            default:
                return null
        }
    }

    return (
        <div className={`hero-component hero-image ${styles.heroContainer} container container-${containerSize ? containerSize : 'fs'} ${index != 0 ? styles.marginTop : ''}`}>
            <div className={styles.desktopImage}>
                <GatsbyImage
                    image={heroImage.gatsbyImageData}
                    alt={`${title?.split('/n').join('') || heroImage.title} | ${mapSiteName[locale]}`}
                    objectFit={(imageObjectFit || 'cover')}
                    loading={index === 0 ? 'eager' : 'lazy'}
                    className={`${fullSize ? styles.fullSize : ''} ${styles.heroImage}  ${styles.desktopGatsbyImage}`}
                    imgStyle={{ width: `${containerSize === 'fs' ? '100%' : ''}` }}
                />
            </div>
            <div className={styles.mobileImage}>
                <GatsbyImage
                    image={(heroMobileImage || heroImage).gatsbyImageData}
                    alt={`${title?.split('/n').join('') || (heroMobileImage || heroImage).title} | ${mapSiteName[locale]}`}
                    objectFit={(imageObjectFit || 'cover')}
                    loading={index === 0 ? 'eager' : 'lazy'}
                    className={`${fullSize ? styles.fullSize : ''} ${styles.mobileGatsbyImage}`}
                />
            </div>
            <div className={`${titleMobile ? '' : styles.mobileTitle}`}>
                {
                    title && titlePosition && titlePosition !== 'none'
                        ?
                        <>
                            <div className={styles.shadow}>&nbsp;</div>
                            {titlePositionDiv()}
                        </>
                        : <></>
                }
            </div>
        </div>
    )
}

export default HeroComponent